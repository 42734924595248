import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Tab, Tabs } from '@mui/material';
import parse from 'html-react-parser';

import { AddYourGenresCard } from '@/components/cards/AddYourGenresCard';
import Loading from '@/components/utility/Loading';
import Card from '@/components/utility/microcomponents/Card';
import SpotifySearchArtist from '@/components/utility/microcomponents/SpotifySearchArtist';
import UserHeader from '@/components/utility/navigation/UserHeader';
import useArtist from '@/hooks/artist/useArtist';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import { SpotifyArtistSearchModel } from '@/models/Spotify';
import AccountAPI from '@/network/AccountAPI';
import { handleApiError } from '@/utility/api';

const ProfilePage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { accountId, refetchAccount } = useAccountContext();
  const { dispatchSnackbar } = useSnackbarContext();

  const { artist, refetchArtist } = useArtist();

  const [newArtistLoading, setNewArtistLoading] = useState<boolean>(false);

  const [value, setValue] = useState<number>(0);

  const updateAccountSpotifyId = useCallback(
    async (newArtist: SpotifyArtistSearchModel) => {
      setNewArtistLoading(true);
      try {
        if (!accountId) return;

        await AccountAPI.updateAccount({ accountId, data: { spotifyArtistId: newArtist.id } });
        await refetchAccount();
        await refetchArtist();
      } catch (error: unknown) {
        handleApiError({
          error,
          dispatchSnackbar,
          customMessage: 'Error updating artist',
        });
      } finally {
        setNewArtistLoading(false);
        if (location.state.redirect !== undefined) {
          navigate(location.state.redirect);
        }
      }
    },
    [accountId, dispatchSnackbar, location.state, navigate, refetchAccount, refetchArtist]
  );

  return (
    <div data-testid="profile-page" className="page-content">
      <UserHeader title="PAGE-TITLES.PROFILE" isProfile={true} />
      {newArtistLoading && (
        <div className="centered-loading">
          <Loading />
        </div>
      )}
      {!artist && !newArtistLoading && (
        <div className="max-w500 ml-auto mr-auto mt20">
          <Card inner className="mb20">
            <h4>Connect your Spotify artist profile </h4>
            <p className="text-faded mt10 small mb20">
              To access this some of our services, you need to connect your Spotify artist account. If you’re a new
              artist and you haven’t set this up yet come back later.
            </p>
            <SpotifySearchArtist
              spotifyArtist={(newArtist: SpotifyArtistSearchModel) => updateAccountSpotifyId(newArtist)}
            />
          </Card>
          {/* <AddYourGenresCard /> */}
        </div>
      )}
      {artist && !newArtistLoading && (
        <>
          <div className="hide-mll-up">
            <Box
              sx={{
                width: '100%',
                marginTop: '20px',
              }}
            >
              <Tabs value={value} onChange={(_, newValue) => setValue(newValue)} aria-label="basic tabs example">
                <Tab label={t('NAVIGATION.PROFILE')} data-testid="to-do-open" />
                <Tab label={t('COMMON.GENRES')} data-testid="to-do-done" />
              </Tabs>
            </Box>
          </div>
          <div className="hide-mll-up">
            {value === 0 && (
              <div className="profile-image-and-bio">
                <img
                  src={
                    artist?.details?.images && artist?.details?.images.length > 0
                      ? artist.details?.images[0].url
                      : '/images/profile-placeholder.svg'
                  }
                  alt=""
                ></img>
                <div className="p20 content">
                  <h3 className="mb8">{artist?.details?.name}</h3>
                  <Card>
                    <div className="d-flex jc-start mb8">
                      <img src={`/images/logos/spotify-logo.svg`} alt={`spotify`} className="activity-logo" />
                      <p className={`small text-brand spotify capitalize pl8 mt-2`}>{t('BRAND.SPOTIFY')}</p>
                    </div>

                    <p id="artist-bio" className="small text-faded">
                      {parse(artist?.details?.description || '')}
                    </p>
                  </Card>
                </div>
              </div>
            )}
            {value === 1 && (
              <>
                <Card inner className="mb20">
                  <SpotifySearchArtist
                    spotifyArtist={(newArtist: SpotifyArtistSearchModel) => updateAccountSpotifyId(newArtist)}
                    isChangeArtist={true}
                  />
                </Card>
                <AddYourGenresCard />

                <div className="text-center p48">
                  <h5>{t('COMMON.WHY-ADD-GENRES')}</h5>
                  <p className="text-faded">{t('COMMON.WHY-ADD-GENRES-DESCRIPTION')}</p>
                </div>
              </>
            )}
          </div>
          <div className="hide-mll-down">
            <div className="d-flex flex-wrap gap20 mt20">
              <div className="profile-image-and-bio flex-w50p">
                <img
                  src={
                    artist?.details?.images && artist?.details?.images.length > 0
                      ? artist?.details?.images[0].url
                      : '/images/profile-placeholder.svg'
                  }
                  alt=""
                ></img>
                <div className="p20 content">
                  <h3 className="mb8">{artist?.details?.name}</h3>
                  <div className="card">
                    <div className="d-flex jc-start mb8">
                      <img src={`/images/logos/spotify-logo.svg`} alt={`spotify`} className="activity-logo" />
                      <p className={`small text-brand spotify capitalize pl8 mt-2`}>{t('BRAND.SPOTIFY')}</p>
                    </div>

                    <p id="artist-bio" className="small text-faded">
                      {parse(artist?.details?.description || '')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex-w50p flex-grow">
                <Card inner className="mb20">
                  <SpotifySearchArtist
                    spotifyArtist={(newArtist: SpotifyArtistSearchModel) => updateAccountSpotifyId(newArtist)}
                    isChangeArtist={true}
                  />
                </Card>
                <AddYourGenresCard />

                <div className="text-center p48">
                  <h5>{t('COMMON.WHY-ADD-GENRES')}</h5>
                  <p className="text-faded">{t('COMMON.WHY-ADD-GENRES-DESCRIPTION')}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfilePage;
